import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { ModalPortal, ToggleModal } from "../../portals/Modal.portal"
import TestRideModal from "../Navbar/Modals/TestRideModal"
import { TextBlock as UITextBlock } from "../UI/Common"
export const fragment = graphql`
  fragment TextBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_TextBlock {
    sectionHeading
    text
  }
`
const TextBlock = ({ sectionHeading, text, location }) => {
  const formId = "TEST_RIDE_TEXT_BLOCK_BUTTON"
  const formType = "BOOK_A_VISIT"
  let textParts
  if (text) {
    text = text.replace(/&nbsp;/g, "")
    textParts = text.match(
      /(^(?:.|\n)*?)<a\s\S*TEST_RIDE_FORM.*?>(.*?)<\/a>((?:.|\n)*)/
    )
  }
  return (
    <>
      <Container className="textblock-component-container">
        <Row className="d-flex justify-content-center">
          <Col xs={11} lg={10} xl={9} className="d-flex flex-column">
            {sectionHeading && (
              <h1>{sectionHeading}</h1>
            )}
            {textParts ? (
              <ToggleModal
                formId={formId}
                formType={formType}
                location={location}
                toggle={show => {
                  return (
                    <>
                      <UITextBlock text={textParts} />
                    </>
                  )
                }}
                content={hide => {
                  return (
                    <ModalPortal hide={hide} size="lg" centered={true}>
                      <TestRideModal location={location} formId={formId} formType={formType} />
                    </ModalPortal>
                  )
                }}
              />
            ) : (
              <>{text && <UITextBlock text={text} />}</>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default TextBlock
